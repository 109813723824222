<template>
  <div>
    <div class="news-content" style="margin-bottom: 60px;">
      <div class="content-inner">
        <h1>{{ article.newsTitle }}</h1>
        <div class="news-date">
          <span>来源:广易拍</span>
          <span>作者:{{ article.newsPublisher }}</span>
          <span>发布时间: {{article.newsPublisherTime}} </span>
          <!--<span>1080 次浏览</span>-->
        </div>
        <div class="news-summary">
          {{ article.newsAbstract }}
        </div>
        <div class="news-body" v-html="article.newsContent"></div>

        <div class="news-pager">
          <div class="news-pager-l" v-if="article.upPage">上一篇：<router-link :to="'/news/'+article.newsCategory+'/'+article.upPage">{{ article.upPageTital }}</router-link></div>
          <div class="news-pager-r" v-if="article.downPage">下一篇：<router-link :to="'/news/'+article.newsCategory+'/'+article.downPage">{{ article.downPageTital }}</router-link></div>
          <div class="clear"></div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import {getDetail} from "@/api/articles";
export default {
	data() {
		return {
			loading: true,
      article:{}
		};
	},
	mounted() {
    this.getDetail()
	},
	methods: {
    getDetail(){
      getDetail({newId:this.$route.params.detailId}).then(response => {
        this.article=response.data
        this.loading = false
      })
    },
	},
  watch:{
    $route(){
      this.getDetail()
    }
  }
};
</script>

